import { forwardRef } from 'react';
import { FormattedDate, IntlShape, useIntl } from 'react-intl';

import { add, formatISO } from 'date-fns';
import styled from 'styled-components';

import { t } from 'translations';
import { RadioGroup, RadioGroupOption, RadioGroupOptionProps } from 'ui';
import { SmallText } from 'ui/styles/text';

import { RatePlan } from '../types';
import {
  periodToPrepaidTextKey,
  periodToProductNameKey,
  periodToProductPlanKey,
  periodToProductPlanNameKey,
} from '../utils/productLocalization';
interface ProductSelectorProps extends Omit<RadioGroupOptionProps, 'labelText'> {
  ratePlans: RatePlan[];
  isPrepaid: boolean;
  prepaidMonths?: number;
  showPrepaidInfo?: boolean;
}

const LabelText = styled.div`
  display: grid;
  gap: 4px;
`;

const getMonetaryValue = (intl: IntlShape) => (value: number, currency: string) =>
  `${intl.formatNumber(value, {
    style: 'currency',
    currency: currency,
    currencyDisplay: 'symbol',
  })} ${currency}`;

export const ProductSelector = forwardRef<HTMLInputElement, ProductSelectorProps>(
  ({ ratePlans, isPrepaid, showPrepaidInfo, prepaidMonths = 0, ...radioProps }, ref) => {
    const intl = useIntl();
    return (
      <RadioGroup>
        {ratePlans.map(({ billingPeriod, amount, currency, trialLength = 0 }) => {
          const price = getMonetaryValue(intl)(amount, currency);
          const priceText = t(periodToProductPlanKey(billingPeriod), {
            price: price,
          });
          const priceTextPrepaidActive = t(periodToPrepaidTextKey(billingPeriod), {
            price: price,
          });
          const prepaidValidTo = formatISO(add(new Date(), { months: prepaidMonths + trialLength }), {
            representation: 'date',
          }).toString();

          const labelText = (
            <LabelText data-testid={billingPeriod}>
              <SmallText $color="white2" $fontSize="medium" $lineHeight="140%">
                {t(periodToProductPlanNameKey(billingPeriod))}
              </SmallText>
              {showPrepaidInfo ? (
                <>
                  <SmallText $color="lightGreen" $fontSize="small" $lineHeight="140%">
                    {t('membership_hub_product_page_prepaid_plan_info', {
                      prepaid_validto: <FormattedDate value={prepaidValidTo} format="default" />,
                      prepaid_amount: getMonetaryValue(intl)(0, currency),
                    })}
                  </SmallText>
                  <SmallText $color="grayLight" $fontSize="small" $lineHeight="140%">
                    {priceTextPrepaidActive}
                  </SmallText>
                </>
              ) : (
                <SmallText $color="lightGreen" $fontSize="small" $lineHeight="140%">
                  {priceText}
                </SmallText>
              )}
            </LabelText>
          );

          return (
            <RadioGroupOption
              {...radioProps}
              ref={ref}
              key={billingPeriod}
              value={billingPeriod}
              labelText={labelText}
              data-testid={periodToProductNameKey(billingPeriod)}
            />
          );
        })}
      </RadioGroup>
    );
  },
);
