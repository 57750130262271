import { ReactNode } from 'react';
import { FormattedDate } from 'react-intl';

import { GetSignupResponseRingSerialStatusEnum } from 'oura-account-api-client';
import { styled } from 'styled-components';

import { SubscriptionState } from 'apps-common/types';
import { t } from 'translations';

import { Column, Row } from '../../styles/layout';
import { RingImage } from '../../styles/media';
import { HeaderText, Paragraph, StepIndicator } from '../../styles/text';
import { breakpoints } from '../../themes/signup';
import { BackButton } from '../BackButton';
import { ErrorBanner } from '../ErrorBanner';
import { useIsDesktopView } from '../useIsDesktopView';
import { AppType, PageType, Step, StyledBaseHeader, StyledRingImageTitleHeaderContainer } from './BaseHeader';
import { MiniBanner } from './MiniBanner';
import { OuraHeaderLogo } from './OuraHeaderLogo';
import { RingPairingNotification } from './RingPairingNotification';

interface DesktopHeaderProps {
  appType: AppType;
  pageType: PageType;
  onBackClick?: () => void;
  title?: ReactNode;
  subTitle?: ReactNode;
  steps?: Step;
  error?: string;
  personalRingImage?: React.ReactNode;
  testId?: string;
  createdAt?: string;
  bannerText?: ReactNode;
  ringSerialStatus?: GetSignupResponseRingSerialStatusEnum;
  pendingCancellation?: boolean;
  subscriptionState?: SubscriptionState;
}

const TiltedRingImage = styled(RingImage)`
  position: absolute;
  top: 120px;
  right: -80px;
  width: 240px;
  height: 240px;
  object-fit: contain;
`;

const AbsoluteRingImage = styled(RingImage)`
  position: absolute;
  right: 30px;
`;

export const DesktopHeader = ({
  pageType,
  appType,
  title,
  subTitle,
  onBackClick,
  error,
  steps,
  testId,
  createdAt,
  bannerText,
  ringSerialStatus,
  pendingCancellation,
  subscriptionState,
}: DesktopHeaderProps) => {
  const [isDesktopView, windowWidth] = useIsDesktopView();
  const posterHeaderTextSize = windowWidth > parseInt(breakpoints.medium, 10) ? 'xxxxlarge' : 'xxxlarge'; // ipads and above use 4xlarges

  const showRingPairingFailureNotification = ringSerialStatus === GetSignupResponseRingSerialStatusEnum.NotReported;
  const ringPairingNotification = showRingPairingFailureNotification && <RingPairingNotification />;

  const getMembershipText = () => {
    if (pendingCancellation) {
      return t('membership_hub_expiring_soon');
    }
    if (subscriptionState === SubscriptionState.MEMBER) {
      return t('membership_hub_member_since', {
        date: <FormattedDate value={createdAt} format="default" />,
      });
    }
    if (subscriptionState === SubscriptionState.EXPIRED) {
      return t('membership_hub_cancelled');
    }
    return null;
  };

  const renderHeader = () => {
    if (pageType === 'landing') {
      return (
        <StyledBaseHeader $marginBottom="0">
          <StyledRingImageTitleHeaderContainer $padding="80px 0 0 0" $flexDirection="row">
            <div style={{ paddingRight: '60px' }}>
              {appType === 'hub' && (
                <HeaderText $fontSize={posterHeaderTextSize}>{t('membership_hub_feature_name')}</HeaderText>
              )}
              {title && (
                <HeaderText $fontSize={posterHeaderTextSize} data-testid={testId}>
                  {title}
                </HeaderText>
              )}
              {subTitle && (
                <Paragraph $fontSize="large" $padding="20px 0 0 0i">
                  {subTitle}
                </Paragraph>
              )}
            </div>
            {appType === 'hub' ? (
              <AbsoluteRingImage src="/oura_black.webp" alt="Ouraring" />
            ) : (
              <RingImage src="/oura_black.webp" alt="Ouraring" />
            )}
          </StyledRingImageTitleHeaderContainer>
        </StyledBaseHeader>
      );
    } else if (pageType === 'create') {
      return (
        <StyledBaseHeader
          $marginTop={showRingPairingFailureNotification ? '0' : '20px'}
          $padding={showRingPairingFailureNotification ? '80px 0 0 0' : '0'}
        >
          {appType === 'signup' && ringPairingNotification}
          <Row $alignItems="flex-end" $padding="0 0 60px 0">
            <OuraHeaderLogo height="29px" width="93px" app={appType} />
            {appType === 'hub' && (
              <Paragraph $fontSize="small" $padding="0 0 0 20px">
                {t('membership_hub_feature_name')}
              </Paragraph>
            )}
          </Row>

          {error && <ErrorBanner>{error}</ErrorBanner>}
          {onBackClick && <BackButton onClick={onBackClick} />}
          {steps && (
            <StepIndicator>
              {t('membership_signup_step', {
                current_step: steps.current,
                total_steps: steps.total,
              })}
            </StepIndicator>
          )}
          {bannerText && appType === 'signup' && (
            <MiniBanner marginBottom={'10px'} marginTop={'20px'}>
              {bannerText}
            </MiniBanner>
          )}
          {title && (
            <HeaderText $fontSize="xxlarge" $fontWeight="300" data-testid={testId}>
              {title}
            </HeaderText>
          )}
          {subTitle && (
            <Paragraph $textAlign="left" $fontSize="medium" $color="grayLightest" as="div" data-testid="pageSubtitle">
              {subTitle}
            </Paragraph>
          )}
        </StyledBaseHeader>
      );
    } else if (pageType === 'hubHome') {
      return (
        <StyledBaseHeader>
          <Row $alignItems="flex-end" $padding="0 0 60px 0">
            <OuraHeaderLogo height="29px" width="93px" app={appType} />
            <Paragraph $fontSize="small" $padding="0 0 0 20px">
              {t('membership_hub_feature_name')}
            </Paragraph>
          </Row>
          <HeaderText $fontSize="xxlarge" $fontWeight="300">
            {title}
          </HeaderText>
          {createdAt ? <Paragraph $margin="0">{getMembershipText()}</Paragraph> : null}
          <TiltedRingImage src="/oura_tilted.webp" alt="Ouraring" />
        </StyledBaseHeader>
      );
    } else if (pageType === 'complete') {
      return (
        <StyledBaseHeader
          $marginTop={showRingPairingFailureNotification ? '0' : '20px'}
          $padding={showRingPairingFailureNotification ? '80px 0 0 0' : '0'}
        >
          {appType === 'signup' && ringPairingNotification}
          <OuraHeaderLogo height="29px" width="93px" app={appType} />
          <StyledRingImageTitleHeaderContainer $padding="100px 0 0 0" $flexDirection="row">
            <Column style={{ paddingRight: '20px', width: '60%' }}>
              {title && (
                <HeaderText $fontSize={posterHeaderTextSize} style={{ overflowWrap: 'break-word' }}>
                  {title}
                </HeaderText>
              )}
              {subTitle && (
                <Paragraph $fontSize="large" $padding={isDesktopView ? '20px 0 0 0' : '0'} data-testid="pageSubtitle">
                  {subTitle}
                </Paragraph>
              )}
            </Column>
            <RingImage src="/oura_gold.webp" alt="Ouraring" />
          </StyledRingImageTitleHeaderContainer>
        </StyledBaseHeader>
      );
    } else if (pageType === 'error') {
      return (
        <StyledBaseHeader>
          <Row $alignItems="flex-end">
            <OuraHeaderLogo height="29px" width="93px" app={appType} />
            {appType === 'hub' && (
              <Paragraph $fontSize="small" $padding="0 0 0 20px">
                {t('membership_hub_feature_name')}
              </Paragraph>
            )}
          </Row>
        </StyledBaseHeader>
      );
    }
    return (
      <StyledBaseHeader>
        <OuraHeaderLogo height="29px" width="93px" app={appType} />
      </StyledBaseHeader>
    );
  };

  return renderHeader();
};
